@tailwind base;
@tailwind components;
@tailwind utilities;

.dark-mode {
    background-image: linear-gradient(to right bottom, #f80000, #f80000, #9e0025, #1a0007, #000000, #000000, #009000, #009000);
}

.light-mode {
    background-color:rgb(190, 194, 201);
}

.hg-button{
    margin: 0.25rem;
    padding: 0.15rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.backg-gradient{
    background-image: linear-gradient(to right bottom, #f80000, #f80000, #9e0025, #1a0007, #000000, #000000, #009000, #009000);
}


.material-icons.md-light { color: #009000; }

.square:after {
    content:"";
    display:block;
    padding-bottom: 100%;
} 

.react-simple-keyboard{
    background-color: transparent !important;
}

.transparent{
    background-color: transparent !important;
}

.yellowButtons{
    background-color: #facc15 !important;
}

.greenButtons{
    background-color: #009000 !important;
}

.grayButtons{
    background-color:#4b5563 !important;
}